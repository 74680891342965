var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('new_template')))]),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{attrs:{"to":"/apps/template/whitelabel","variant":"outline-primary"}},[_vm._v(_vm._s(_vm.$t('buttons.cancel')))])],1)],1),_c('template-stepper',{attrs:{"current":_vm.current,"steps":_vm.steps},on:{"onChange":_vm.handleStepChange}}),(_vm.current === 1)?_c('div',[_c('template-selector',{on:{"onSelected":function (type) {
          _vm.setEnabled(1);
          _vm.handleStepChange(2);
          _vm.setCreateTemplate({ type: type });
        }}})],1):(_vm.current === 2)?_c('div',[_c('template-form',{on:{"onSubmit":function (name) {
          _vm.setEnabled(2);
          _vm.handleStepChange(3);
          _vm.setCreateTemplate({ name: name });
        }}})],1):(_vm.current === 3)?_c('div',[_c('template-creating-progress',{attrs:{"create-template":_vm.createTemplate},on:{"onError":function($event){return _vm.handleStepChange(1)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }