<script>
export default {
  name: "GanamosImgPreview"
}
</script>

<template>
  <b-img src="./assets/ganamosTemplate.png"></b-img>
</template>


<style scoped lang="scss">

</style>