<script>
import { BCard, BCardTitle, BCol, BRow } from "bootstrap-vue";
import TemplateStepper from "@/views/pages/templates/common/components/TemplateStepper.vue";
import TemplateSelector from "@/views/pages/templates/whitelabel-templates/templates-create/TemplateSelector.vue";
import TemplateForm from "@/views/pages/templates/whitelabel-templates/templates-create/TemplateForm.vue";
import TemplateCreatingProgress from "@/views/pages/templates/whitelabel-templates/templates-create/TemplateCreatingProgress.vue";
import store from "@/store";

export default {
  name: "index",
  components: {
    BCol,
    BRow,
    TemplateCreatingProgress,
    TemplateForm,
    TemplateSelector,
    TemplateStepper,
    BCard,
    BCardTitle,
  },

  data() {
    return {
      current: 1,
      createTemplate: {
        type: "",
        name: "",
      },
    };
  },

  methods: {
    handleStepChange(value) {
      this.current = value;
    },

    setEnabled(index) {
      this.steps[index].enabled = true;
    },

    setCreateTemplate({ name, type }) {
      if (name) this.createTemplate.name = name;
      if (type) this.createTemplate.type = type;
    },
  },

  created() {
    store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },

  computed: {
    steps() {
      return [
        {
          id: 1,
          name: this.$t('select_template'),
          enabled: true,
        },
        {
          id: 2,
          name: this.$t('provide_data'),
          enabled: false,
        },
        {
          id: 3,
          name: this.$t('creating_template'),
          enabled: false,
        },
      ]
    }
  }
};
</script>

<template>
  <b-card no-body class="p-2">
    <b-card-title class="mb-2">{{ $t('new_template') }}</b-card-title>
    <b-row>
      <b-col md="12" class="d-flex justify-content-end">
        <b-button to="/apps/template/whitelabel" variant="outline-primary"
          >{{ $t('buttons.cancel') }}</b-button
        >
      </b-col>
    </b-row>

    <template-stepper
      :current="current"
      :steps="steps"
      @onChange="handleStepChange"
    />
    <div v-if="current === 1">
      <template-selector
        @onSelected="
          (type) => {
            setEnabled(1);
            handleStepChange(2);
            setCreateTemplate({ type });
          }
        "
      />
    </div>
    <div v-else-if="current === 2">
      <template-form
        @onSubmit="
          (name) => {
            setEnabled(2);
            handleStepChange(3);
            setCreateTemplate({ name });
          }
        "
      />
    </div>
    <div v-else-if="current === 3">
      <template-creating-progress
        @onError="handleStepChange(1)"
        :create-template="createTemplate"
      ></template-creating-progress>
    </div>
  </b-card>
</template>

<style scoped lang="scss"></style>
