<script>
import { BOverlay } from "bootstrap-vue";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import { mapState } from "vuex";
import { axiosErrorHandle } from "@/@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { translatableText } from "@core/utils/utils";

const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "TemplateCreatingProgress",
  components: { BOverlay },
  props: {
    createTemplate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorCreatingTemplates: false,
    };
  },
  computed: {
    ...mapState("app-whitelabel-templates", ["loadingAdd"]),

    creatingText() {
      return `${this.$t("creating")} ${this.$t("template")}: ${
        this.createTemplate.name
      }...`;
    },
    
  },
  
  async mounted() {
    try {
      this.$store.commit("app-whitelabel-templates/SET_LOADING_ADD", true);
      const params = {
        ...this.createTemplate,
        whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
      };

      const { data: template } = await this.$store.dispatch(
        "app-whitelabel-templates/addTemplate",
        params
      );
      const routeName = `${this.createTemplate.type}-template-preview`;
      await this.$router.push({
        name: routeName,
        query: { id: template._id },
      });
    } catch (error) {
      this.errorCreatingTemplates = true;
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: "XIcon",
          title: this.$t("template"),
          text: await translatableText({ text: axiosErrorHandle(error) }),
          variant: "danger",
        },
      });
      this.$emit("onError");
    } finally {
      this.$store.commit("app-whitelabel-templates/SET_LOADING_ADD", false);
    }
  },
  
  beforeCreate() {
    if (!store.hasModule(MODULE_APP_STORE_NAME)) {
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    }
  },
};
</script>

<template>
  <div class="pb-5">
    <b-overlay opacity="0" :show="true" class="mt-4">
      <div class="py-2"></div>
    </b-overlay>
    <p class="text-center">{{ creatingText }}</p>
  </div>
</template>

<style scoped lang="scss"></style>
